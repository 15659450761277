<template>
  <alert-banner v-if="showBanner" id="alert-banner" class="boi-banner">
    BOI questions?
    <div
      class="pl-1 pr-1"
      @click="logBoiBannerInteraction('boi-faqs-page')"
    >
      <router-link to="/faq/621379ca-3fb0-40e4-be32-a95a2ef2e11f">
        Learn more<span class="hidden-xs"> about the new federal BOI requirements</span>
      </router-link>.
    </div>
    You can also
    <div
      class="pl-1 pr-1"
      @click="logBoiBannerInteraction('hire-us-page')"
    >
      <router-link to="/hire-us">
        Hire us
      </router-link>
    </div>
    <span class="hidden-xs">to file for you.</span>
  </alert-banner>
</template>

<script>
import { mapGetters } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'BoiFaqsBanner',
  components: {
    AlertBanner: () => import('@/components/shared/AlertBanner.vue'),
  },
  computed: {
    ...mapGetters('account', {
      websiteId: 'websiteId',
    }),
    ...mapGetters('session', {
      token: 'getToken',
    }),
    hideIfRoute() {
      const hiddenRoutes = [
        'stageline-required-info',
        'stageline-v2-required-info',
        'stageline-post-payment',
        'stageline-v2-post-payment',
        'verify-order',
      ]

      const hiddenSlideLayoutTypes = [
        'verifyOrder',
      ]

      return !!(hiddenRoutes.includes(this.$route.name)) || hiddenSlideLayoutTypes.includes(this.currentSlide?.layout_type)
    },
    excludedWebsites() {
      // These IDs are temporary. This file will be deleted at the end of the year.
      return [
        'f8ce53f4-bf5a-49a4-b33a-f5e4e081de10', // www.rvmail.com
        '4e597cd7-7f51-4567-9ac8-c61fae318b5b', // www.sasquatchmail.com
        'de1d12e8-6b33-458b-872c-57471564960e', // www.newyorkllcs.com
        'fe4d2044-c374-4ec0-9dde-59f3fedaf7af', // www.lawoncall.com
        '80cf0591-6342-419a-adb9-f7063351f6dd', // www.formed.com
      ].includes(this.websiteId)
    },
    showBanner() {
      return this.token &&
        !this.excludedWebsites &&
        !this.hideIfRoute
    },
  },
  methods: {
    async logBoiBannerInteraction(location) {
      await createOrFindClientInteractionLog({
        category: 'page-interaction',
        subCategory: location,
        interaction: {
          type: 'button',
          action: 'redirect',
          name: 'vehicle-boi-faqs-banner',
        },
        incompleteLogFromToday: true,
      })
    },
  },
}

</script>
<style lang="scss">
$primary: #283d96;
$primary-dark: darken($primary, 10%);
$yellow: #fff0b0;

.alert-banner-component.boi-banner {
  margin-bottom: .5rem;
  background-color: $yellow;
  color: $primary-dark;

  a {
    color: $primary;
  }
}

@media (max-width: 768px) {
  .hidden-xs {
    display: none;
  }
}

@media print {
  .alert-banner-component.boi-banner {
    display: none;

    div {
      display: none
    }
  }
}
</style>
