var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBanner
    ? _c(
        "alert-banner",
        { staticClass: "boi-banner", attrs: { id: "alert-banner" } },
        [
          _vm._v("\n  BOI questions?\n  "),
          _c(
            "div",
            {
              staticClass: "pl-1 pr-1",
              on: {
                click: function ($event) {
                  return _vm.logBoiBannerInteraction("boi-faqs-page")
                },
              },
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/faq/621379ca-3fb0-40e4-be32-a95a2ef2e11f" } },
                [
                  _vm._v("\n      Learn more"),
                  _c("span", { staticClass: "hidden-xs" }, [
                    _vm._v(" about the new federal BOI requirements"),
                  ]),
                ]
              ),
              _vm._v(".\n  "),
            ],
            1
          ),
          _vm._v("\n  You can also\n  "),
          _c(
            "div",
            {
              staticClass: "pl-1 pr-1",
              on: {
                click: function ($event) {
                  return _vm.logBoiBannerInteraction("hire-us-page")
                },
              },
            },
            [
              _c("router-link", { attrs: { to: "/hire-us" } }, [
                _vm._v("\n      Hire us\n    "),
              ]),
            ],
            1
          ),
          _c("span", { staticClass: "hidden-xs" }, [
            _vm._v("to file for you."),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }